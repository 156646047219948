.cussor-pointer {
  cursor: pointer;
}

.list-group-item .dropdown-toggle:after {
  content: "";
}

.dzu-previewContainer{
  padding: 0px 3%;
  min-height: 50px;
}