.pass_in {
   float: right !important;
   margin-top: -56px !important;
}

 .prevStyle {
   background: rgb(247, 250, 252);
   border: 1px solid rgb(238, 238, 238);
   color: rgb(51, 51, 51);
   border-radius: 4px;
   font-size: 14px;
   font-weight: 600;
   padding: 0.55em 2em;
   margin-right: 1rem;
}

.nextStyle {
   background: rgb(82, 177, 65);
   border-width: 0px;
   color: rgb(255, 255, 255);
   border-radius: 4px;
   font-size: 14px;
   font-weight: 600;
   padding: 0.55em 2em;
}

.next_pre_btn{
   float: right;
}

.accordion__header {
   background: #ececfa !important;
   border-color: #ececfa !important;
}

form#step-form-horizontal div:first-child > div:last-child {
    text-align: left !important;
}

.accordion-active-header .accordion__header:not(.collapsed) {
   background: #8280f3 !important;
    border-color: #8280f3 !important;
}
.select_plan_btn{
   float: right !important;
}
