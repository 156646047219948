.card {
   /* width: auto !important; */
}
 .setting_class{
   margin-left: 26px !important;
}

.setting_topm{
   margin-top: -10px !important;
}
.setting_topm_tt{
   inset: 0px auto auto 0px !important;
} 

.setting_font_size{
   font-size: 15px !important;
    color: #7e7e7e !important;
}

.hamburger .line{
   background : #f3f5f2  !important;
   background-color : #f3f5f2  !important;
}

/* [data-header-position="fixed"] .search {
   position: fixed;
   top: 0;
   width: 100%;
} */