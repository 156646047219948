
.custom-control-new{
  position: initial !important;
}

.prefix_sel{
  height: 225px !important;
}

.area_code_sel{
  height: 195px !important;
}

.step_2{
  width: 100% !important;
}

.cart_backgournd {
  background: #cecaca3b !important;
  height: auto !important;
  height: calc(100% - 30px) !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-color: #fff !important;
  background-clip: border-box !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.75rem !important;
}

.item1{
  padding: 0.4rem 0rem !important;
}

.bottum_fix{
  left: 0 !important;
  bottom: 0 !important;
}

.td_width{
  width: 21% !important;
}