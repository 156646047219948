
.group_field .easy-edit-component-wrapper{ width: 75% !important;}
/* .group_field .easy-edit-wrapper{ font-size: 20px; font-weight: 600; color: #010101;} */


.cursor-pointer { cursor: pointer;}

.contact-form .nav-tabs .nav-link, .contact-form .nav-tabs .nav-item a { border: 1px solid;     border-color: #dee2e6 #dee2e6 #fbfbfb;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem; }

.contact-form .nav-tabs .nav-link, .contact-form .nav-tabs .nav-item a:hover { border-left-color: #52b141; border-top-color: #52b141; border-right-color: #52b141; }

/* .ringgroup .table-responsive tr td a{ color: #ff0d0d;} */