.msg_cotainer {
  background: #52b141 !important;
  margin-left: 10px !important;
  border-radius: 0 0.75rem 0.75rem 0.75rem !important;
  padding: 5px 15px !important;
  color: #fff !important;
  position: relative !important;
}

.msg_time{
  display: block !important;
    font-size: 11px !important;
    color: #fff !important;
    margin-top: 5px !important;
    opacity: 0.5 !important;
}
.msg_cotainer_send{
  background: #f7f7f7 !important;
    padding: 5px 15px !important;
    border-radius: 6px 0px 6px 6px !important;
    margin-right: 10px !important;
    color: #222 !important;
    position: relative !important;
    text-align: right !important;
}

.msg_time_send{
  width: 30px !important;
  height: 30px !important;
  display: block !important;
  max-width: 30px !important;
  min-width: 310px !important;
  
}
.reply_text{
  height: 50px !important;
}