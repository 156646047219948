.App {
  font-family: sans-serif;
  text-align: center;
}

.search_box{
  width: 30% !important;
}

.music_on_hold > option:last-child {
  color: gray !important;
  background-color: transparent !important;
  text-decoration: none !important;

}
