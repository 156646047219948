

.header_onboarding{
   padding-left: 0rem !important;
   height: 4rem !important;
}

.zongo_logo{
   height: 3rem !important;
}

.header_profile_onboarding img {
   width: 45px !important;
   height: 45px !important;
}