.input_height{
  height: 54px !important;
}

/* ._NGZSv{ */
  /* padding: 3px !important; */
  /* width: 26px !important;
  height: 26px !important;
  min-width: 26px !important;
  min-height: 26px !important; */
/* }6 */

.note_header{
  position: fixed !important;
  top: 392px !important;
  right: 5px !important;
}
.chatbox .user_info span {
  max-width: 230px !important;
  
}

.user_info_read_more{
  float: right !important;
}

.chatbox .nav {
  background:#72bde8 !important;
}


/* SIDEBAR css by sher
====================*/
.sidebar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 30rem;
  z-index: 991;
  margin-right: -30rem;
}
.sidebar {
  position: absolute;
  top: 120px;
  right: 0;
  height: inherit;
  width: 30rem;    
  background-color: #fff;
  transition: all .2s ease;
  box-shadow: none;
  border-left: 1px solid #d8d8d8;	
}
.menu {
    font-size: 1.2rem;
    position: fixed;
    top: 45%;
    right: 0;
    z-index: 999999;
    background: #000;
    width: 30px;
    height: 30px;
	transition: all .2s ease;
	
}
.sidebar-title {
  text-align: center;
}
.dedication {
  text-align: justify;
  font-size:1.5rem;
  padding: 0 3rem;
}

/* jQ TOGGLE UTIL
====================*/
.show-sidebar {
  margin-right: 0;
	transition: all .2s ease;
}
.push-body {
  margin-right: 30rem;
	transition: all .2s ease;
}


.move-to-left {
  transform: translateX(-350px);
}
.move-to-left-partly {
  transform: translateX(0px);
	transition: all .2s ease;
}
.NewTabswrap.sidebar {
  height: 100vh;
  width: 350px;
  position: fixed;
  top: 0;
  z-index:9;
  right: -350px;
  background-color: #FFF;
  transition: transform .7s ease-in-out;
  padding: 0;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 10%), 0 7px 20px 0 rgb(82 177 65 / 43%);
}
.sidebar-tab {
  height: 42px;
  width: 38px;
  position: fixed;
  /* top: 450px; */
  top: 120px;
  z-index: 1;
  right: 0;
  background-color: #52b141;
/*  box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.5);*/
  transition: transform .7s ease-in-out;
  border-radius: 10px 0 0 10px;
}
#sidebar-tab-text{ 
    width: 400px;
}
.vertical-text {
	transform: rotate(360deg);
/*	transform-origin: left 2rem;*/
/*  vertical-align:middle;*/
	padding: 5px;
}
.sidebar-tab .arrow {
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  position: relative;
  /* transform: rotate(90deg); */
  transition: all 0.5s ease-in-out;
  width: 32px;
  height: 32px;
  z-index: 1;
  color: white;
  font-size: x-large;
}
/* .sidebar-tab .arrow:after, .sidebar-tab .arrow:before {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  transition: all 0.25s ease-in-out;
  border-radius: 10px;
  background: #fff;
  width: 16px;
  height: 3.2px;
  top: 14.4px;
} */
.sidebar-tab .arrow:after {
  transform: rotate(44deg);
  left: 3.2px;
}
.sidebar-tab .arrow:before {
  right: 3.2px;
  transform: rotate(-44deg);
}
.sidebar-tab .arrow.active:after {
  transform: rotate(-44deg);
}
.sidebar-tab .arrow.active:before {
  transform: rotate(44deg);
}

.sidebar-tab .btn.btn-primary {
  background-color: #F50057;
  border-color: #F50057;
}

.NewTabswrap.sidebar .nav-item{ 
	display:block;
	width: 50%;
}
/* .NewTabswrap .nav-pills .nav-link { background: #fff; text-align: center;} */
.NewTabswrap .nav-pills .nav-link {
  border-radius: 15px 15px 0 0 !important;
  padding: 0.75rem 1.25rem;
  text-align: center;
  box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.5);
  background: #fff;
}

.NewTabswrap .nav-pills .nav-item .active{
  background: #52b141;
  color : #fff;
}

.default-tab .nav-item .active i {
  color : #fff;
}


.NewTabswrap .nav.nav-pills{    
	position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;}

/* CallTabs */
.custom-tab-1.CallTabs .nav-tabs .nav-item{ width: 33.33%; text-align: center;}
.custom-tab-1.CallTabs p { line-height: 24px; font-size: 14px; color: #52b141}
.custom-tab-1.CallTabs p span{ font-size: 1.1rem; display: block; font-weight: 600; color: #111;}
.custom-tab-1.CallTabs p span a{ display: inline-block; color: #52b141; margin-left: 5px; font-size: 15px;}

/* .custom-tab-1.CallTabs .tab-pane{ height: calc(100vh - 162px);} */
.UserData{ height: 100%; overflow-y: scroll; width: 100%; padding: 15px 10px 0 15px;}
.UserCallDetail{ width: 100%; padding: 15px 15px 10px; background: #e6f5e3;}

.dialUser{ width: 100%; margin-bottom: 20px; position: relative; color: #04a2f5; font-size: 18.5px; font-weight: 600;}
.dialUser .dropdown.togmenu{ position: absolute; top: -8px; right: 0;}
.dialUser .dropdown-toggle{ padding: 0px; color: #52b141;font-size: larger;}
.dialUser .dropdown-toggle::after{ display: none;}
/* .dialUser .dropdown-menu.show{ left: auto !important; right: 0 !important;} */
.dialUser .dropdown-toggle.usertxt{ color: #04a2f5; font-size: 18.5px;}
.btn-usertxt{ color: #04a2f5; font-size: 18.5px;}

.dial-search .form-control{ height: 42px; border-radius: 5px;}

.CallUser{ width: 100%; padding: 10px 0;}
.CallUser a{ border-radius: 50%; border: 1px solid #52b141; width: 40px; height: 40px; display: inline-block; margin: 0 5px; color: #52b141; font-size: 18px; line-height: 40px;}
.CallUser a:hover{ background: #52b141; color: #fff;}

.CallUser button{ background: transparent;border-radius: 50%; border: 1px solid #52b141; width: 40px; height: 40px; display: inline-block; margin: 0 5px; color: #52b141; font-size: 18px; line-height: 40px;}
.CallUser button:hover{ background: #52b141; color: #fff;}
.CallUser button.active{ background: #52b141; color: #fff;}
.CallUser button.hangup{ background: #df2706; color: #fff;border: 1px solid #df2706;}

.HistoryIndent{ padding: 15px 10px 15px 15px;}
/* Scrollbar Styling */
.HistoryIndent::-webkit-scrollbar, .UserData::-webkit-scrollbar{
    width: 5px;
}
/*
.HistoryIndent::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
*/
.HistoryIndent::-webkit-scrollbar-thumb,  .UserData::-webkit-scrollbar-thumb{
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #6d6d6d; 
}

/* .HistoryIndent { position: relative;} */
.HistoryIndent p { color: rgb(10, 158, 22);}
.HistoryIndent span { color: #000; font-weight: 600;}
.HistoryIndent .edit-btn{ border-radius: 50%; width: 35px; height: 35px; background: #52b141; text-align: center; color: #fff; position: absolute; top: 10px; right: 40px; line-height: 35px; font-size: 16px;}
.HistoryIndent .delete-btn{ border-radius: 50%; width: 35px; height: 35px; background: #ff6d4d; text-align: center; color: #fff; position: absolute; top: 0px; right: 0; line-height: 35px; font-size: 16px;}

.callDail{
	width: 100%;
	height: 320px;
	background:#52b141;
	position: absolute;
	left: 0; 
	top: 0; 
	right: 0;
	bottom: 0;
}
.callDail li{
	display: inline-block;
	width: 32%;
	padding:11.5px 5px;
	text-align: center;
}
.callDail li span{
	width: 55px;
	height: 55px;
	line-height: 55px;
	border: 2px solid #fff;
	color: #fff;
	font-size: 18px;
	text-align: center;
	font-weight: 500;
	display: inline-block;
	margin: 0 auto;
	border-radius: 50%;
	cursor: pointer;
}
.callDail li:hover span{
	background: #fff;
	color: #52b141;
}

.HistoryIndent .accordion__header {
    padding: 10px;   
}

.call-txt{
	color: #363636;
}

/* history tab */
.callHistory{ width: 100%; position: relative;}
.callHistory ul { padding: 10px;}
.callHistory li{ border-bottom: 1px solid #eee; padding: 12px 0; font-size: 16px; color: #666; line-height: 22px;}
.callHistory li span{ display: block; margin: 4px 0;}
.callHistory li span .greenColor{ color:#52b141 }
.callHistory li span .redColor{ color:#EE2222 }
.time-mins{ color: #04a2f5;}
.callHistory li span .fa{ margin-right: 8px;}