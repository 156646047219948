.App {
  font-family: sans-serif;
  text-align: center;
}

.card{
  width: 100%;
}

.time_field_h{
  color: #52b141 !important;
  height: 40px !important;
  border: 2px solid !important;
}

.time_field_hp{
  color: #52b141 !important;
  height: 45px !important;
  border: 2px solid !important;
}

.time_field_red{
  color: #de220e !important;
  height: 45px !important;
  border: 2px solid !important;
}

.err_msg{
  width: 100% !important;
}

.customToggle .custom-control { display: inline-block; margin-left: 10px; vertical-align: -3px;}
.customToggle .custom-control-label::before { border-radius: 30px !important;border-color: #f03905;}
.customToggle .custom-switch .custom-control-label::after { top: calc(0.15625rem + 3px); left: calc(-2.25rem + 4px);border-color: #f03905;background-color: #f03905;}
.customToggle .custom-switch .custom-control-input:checked ~ .custom-control-label::after { left: calc(-2.25rem + 5px);}
.customToggle .custom-switch .custom-control-label::before {width: 2.20rem; border-color: #f03905;}

.nav-pills .nav-link.active{
  color: #fff !important;
  background-color: #52b141 !important;
}

.td_padding_margin{
 padding: 2px 2px !important;
}

