.show_div {
   display: block;
}

.show_hide {
   display: none;
}

.show_div_busy {
   display: block;
}

.show_hide_busy {
   display: none;
}

.show_div_no_ans {
   display: block;
}

.show_hide_no_ans {
   display: none;
}

.show_div_not_reg {
   display: block;
}

.show_hide_not_reg {
   display: none;
}

.show_div_follow_me {
   display: block;
}

.show_hide_follow_me {
   display: none;
}

.nav-tabs .nav-item {
   margin-bottom: 10px !important;
}