.chat_btn_clr{
  color:red !important;
}

.ic_cotainer {
  background: #e6f5e3 !important;
  margin-left: 10px !important;
  border-radius: 0 0.75rem 0.75rem 0.75rem !important;
  padding: 5px 15px !important;
  color: #fff !important;
  position: relative !important;
}

.ic_cotainer_send{
  background: #f7f7f7 !important;
    padding: 5px 15px !important;
    border-radius: 6px 0px 6px 6px !important;
    margin-right: 10px !important;
    color: #222 !important;
    position: relative !important;
    text-align: right !important;
}

.msg_time_file{
 
}

.msg_time_send_file{
  
}
.msg_time_msg{
  word-break: break-all !important;
  width: 30px !important;
  height: 30px !important;
  display: block !important;
  max-width: 30px !important;
  min-width: 310px !important;
  color: #2f2929 !important;
  
}
.msg_time_send_msg{
  word-break: break-all !important;
  width: 30px !important;
  height: 30px !important;
  display: block !important;
  max-width: 30px !important;
  min-width: 310px !important;
}

.chat_time_send{
  word-break: break-all !important;
    width: 30px !important;
    height: 30px !important;
    display: block !important;
    max-width: 30px !important;
    min-width: 310px !important;
    font-size: 11px !important;
}

.chat_time{
  word-break: break-all !important;
  width: 30px !important;
  height: 30px !important;
  display: block !important;
  max-width: 30px !important;
  min-width: 310px !important;
  font-size: 11px !important;
}

.interchat_header{
  background-color: #e6f5e3 !important;
}


.badge_clr{
  background-color: #FFA118 !important;
}

.interchat_send_btn{
  border-top-right-radius: 1 !important;
   border-bottom-right-radius: 1 !important;
}

.emoji_file_btn{
  height: 50px !important;
}

.image_hw{
  height: 100px;
  width: 100px;
}

.send_img{
  border-bottom: 1px solid #52b141;
  text-align: center;
}

.ic_time{
  
    font-size: 11px !important;
    color: #2f2929 !important;
    margin-top: 5px !important;
    opacity: 0.5 !important;
}

.ff{
  word-break: break-all !important;
  width: 30px !important;
  height: 30px !important;
  display: block !important;
  max-width: 30px !important;
  min-width: 310px !important;
  font-size: 11px !important;
}

.emojis_icon{
  font-size:40px !important;
  color:#baba2a;
}

.paper_icon{
  font-size:40px !important;
  color:rgb(18, 18, 13);
}

.arrow_icon{
  font-size:22px !important;
}

.ic_header_left {
  height: 100%;
  display: flex;
  align-items: center;
}

.ic_header_left  .search_bar{
  display: flex;
    align-items: center;
    height: 100%;
}

.ic_header_left textarea{
  background: #fff !important;
  
    border-color: transparent;
    color: #6e6e6e !important;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ebebeb;
    border-width: 1px 0px 1px 1px;
    font-weight: 300;
}

.ic_header_left .search_bar .search_icon {
  background: #fff !important;
  height: 56px;
  padding: 15px 20px 15px 0 !important;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border: 1px solid #ebebeb;
  border-width: 1px 1px 1px 0;
}

.EmojiPickerReact{
  width: 300px !important;
}


.scroll-container {
  height: 300px; /* Set the desired height */
  width: 100%;   /* Set the desired width */
  overflow-x: auto; /* Enable vertical scrollbar when content overflows */
  /* You can also use 'overflow-x: auto;' for horizontal scrollbar if needed */
}