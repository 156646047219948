.show_div {
   display: block;
}

.show_hide {
   display: none;
}

.show_div_busy {
   display: block;
}

.show_hide_busy {
   display: none;
}

.show_div_no_ans {
   display: block;
}

.show_hide_no_ans {
   display: none;
}

.show_div_not_reg {
   display: block;
}

.show_hide_not_reg {
   display: none;
}

.show_div_follow_me {
   display: block;
}

.show_hide_follow_me {
   display: none;
}

.cal_width_5 {
   width: 5% !important;
}

.inline_edit_div{
   color: #52b141 !important;
    border: 2px solid !important;
    border-radius: 12px !important;
    padding: 5px !important;
}

.vm_enable {
   display: block;
}

.vm_disable {
   display: none;
}

.check_box_pointer {
   display: block;
   position: relative;
   padding-left: 35px !important;
   margin-bottom: 12px !important;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }

 .MuiInputBase-input {
   color: #52b141 !important;
   border: 2px solid !important;
   border-radius: 12px 0px 0px 12px !important;
   padding: 5px !important;
}

.time_field_h{
   color: #52b141 !important;
   height: 40px !important;
   border: 2px solid !important;
}



