.App {
  font-family: sans-serif;
  text-align: center;
}

.ringtabs.nav-tabs .nav-link{ font-size: 18px !important; font-weight: 500 !important; color: #101010 !important; padding: 12px 30px !important;}
.ringtabs.nav-tabs .nav-link.active, .ringtabs.nav-tabs .nav-item.show .nav-link { color: #52b141 !important; background: #fff !important}
.ringgroup .card{ box-shadow: 0px 6px 24px 0px rgb(82 177 65 / 56%); border-radius: 15px !important;}

.ringgroup label{ font-size: 15px; color: #888585; font-weight: 500;}
.ringgroup p{ font-size: 20px; font-weight: 600; color: #010101;}
.ringgroup .height45{ height: 45px !important;}
.ringgroup .bootstrap-select>.dropdown-toggle{ padding: 10px 15px;}
/* .ringgroup .btn-primary.btn-sm{ padding: 2.5px 7px 1px!important; line-height: 22px; cursor: pointer;} */
.trash-btn{ padding: 8px 10px !important; font-size: 15px !important; border-radius: 6px !important; margin-top: 1px;}

.ringgroup .easy-edit-component-wrapper{ width: 75% !important;}
.ringgroup .easy-edit-wrapper{ font-size: 20px; font-weight: 600; color: #010101;}

.customToggle .custom-control { display: inline-block; margin-left: 10px; vertical-align: -3px;}
.customToggle .custom-control-label::before { border-radius: 30px !important;border-color: #f03905;}
.customToggle .custom-switch .custom-control-label::after { top: calc(0.15625rem + 3px); left: calc(-2.25rem + 4px);border-color: #f03905;background-color: #f03905;}
.customToggle .custom-switch .custom-control-input:checked ~ .custom-control-label::after { left: calc(-2.25rem + 5px);}
.customToggle .custom-switch .custom-control-label::before {width: 2.20rem; border-color: #f03905;}

.cursor-pointer { cursor: pointer;}

/* .ringgroup .table-responsive tr td a{ color: #ff0d0d;} */