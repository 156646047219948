.tbl_td{
  width: 13%;
}

.tab_sel{
  width: 150px !important;
}

.red_cb{
  color: red !important;
  border-color: red !important;
}

.green_cb{
  color: green !important;
  border-color: green !important;
}

.accordion__header{
  background: #5856CE !important;
  border-color: #5856CE !important;
  box-shadow: 0 15px 20px 0 #614cbb45 !important;
}

.accordion-primary .accordion__header.collapsed {
  background: #ececfa !important;
  border-color: #ececfa !important;
}

.pro_pic{
  margin-left: 60px !important;
  margin-top: 5px !important;
}
