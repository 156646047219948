.webphone_header {
   /* background-color: #56C7CE !important; */
   color: #fff;
   background-color: #52b141 !important;
   border-color: #52b141 !important;
}


.reg{
   background: #efece8 !important;
   padding: 15px !important;
}

.header .header-content{
   border-bottom: 1px solid #dee2e6;
}

.container-fluid{
   margin-top: 10px;
}