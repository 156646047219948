
.plan_card{ padding:55px 15px 25px; position: relative;}
.plan_card h3{ font-size: 18px; font-weight: 500; text-align: center; margin-bottom: 15px;}
.plan_card p{ text-align: center !important; color: #111; margin-bottom: 10px;}
.price-title{ width: 100%; margin-bottom: 5px; font-size: 26px; font-weight: 600; text-align: center; color: #111;}  
.price-title span{ text-decoration: line-through; font-size: 18px; display: block; font-weight: 400; color: #666;}
.plan_card .btn{ padding: 8px 20px; border-radius: 5px; font-size: 14px; margin: 0 5px;}
.plan_card li{ font-size: 15px; color: #232323; padding: 6px 0 6px; position: relative;}
.plan_card li span{ margin-right: 5px;}
.most-txt{ position: absolute; top: 15px; display: inline-block; left: 0; right: 0; margin: 0 auto; border-radius: 30px; padding: 3px 10px; background: #f9a220; color: #fff; text-align: center;
  font-size: 13px; width: 105px;}
.value-txt{ position: absolute; top: 15px; display: inline-block; left: 0; right: 0; margin: 0 auto; border-radius: 30px; padding: 3px 10px; background: #52b141; color: #fff; text-align: center;
  font-size: 13px; width: 95px;}

  .pricewrap{ width: 100%;}
  .pricewrap .nav{ display: block; text-align: center;}
  .pricewrap .nav li.nav-item{ display: inline-block !important;}

  .cart_backgournd{
        background: #cecaca3b !important;
        height: auto !important;
        /* height: calc(100% - 30px) !important; */
  }
  .user_btn{
    background: #ded6d6 !important;
    
  }

  .hr_class{
    margin-right: 0!important;
    margin-left: 0!important;
  }

  .link_onboarding {
    cursor: pointer;
    position: relative;
  }

  ol, ul {
    padding-left: 0rem !important;
  }
  